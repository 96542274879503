.services--container{
    grid-template-columns: repeat(3,300px);
    justify-content: center;
    column-gap: 1.8rem;
}
.services--box{
    display: none;
}

.services--content{
position: relative;
border: 1px solid rgba(0,0,0,0.1);
background-color: var(--container-color);
padding: 4rem 0 2rem 2.5rem;
}
.services__icon{
    display: block;
    font-size: 2rem;
    color: var(--title-color);
    margin-bottom:var(--mb-1) ;
}
.services--title{
    font-size: 1.2rem;
    margin-bottom: var(--mb-1);
    font-weight: var(--font-medium);
}
.services--button{
    color: var(--title-color);
    font-size: var(--small-font-size);
    display: inline-flex;
    column-gap: 0.30rem;
    cursor: pointer;
}
.services--button--icon{
    font-size: 1rem;
    transition: 0.3s;
}
.services--button:hover .services__button-icon{
    transform: translateX(00.25rem);
}
.service--box{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: var(--z-modal);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    padding: 0 1rem;
    visibility: hidden;
    transition: 0.3s;
}
.service--box--content{
    width: 500px;
    position: relative;
    background-color: var(--container-color);
    padding: 4.5rem 2.5rem 2.5rem;
    border-radius: 1.5rem;
    
}
.service--box--close{
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.5rem;
    color: var(--title-color);
    cursor: pointer;
}
.services--box--title,.services--box--description{
    text-align: center;
}
.services--box--title{
    font-size: var(--h3-font-size);
    font-weight: normal;
    margin-bottom: var(--mb-1);
}
.services--box--description{
    font-size: var(--small-font-size);
    padding: 0 3.5rem;
    margin-bottom: var(--mb-2);
    text-align: center;
}
.services--box--service{
    display: flex;
    align-items: center;
    column-gap: .5rem;
}
.services--box--icon{
    color: var(--title-color);
    font-size: 1.2rem;
}

.services--box--info{
    font-size: var(--small-font-size);
}

/* active Box selection */
.active--box{
    opacity: 1;
    visibility: visible;
}
/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {

    .services--container{
        grid-template-columns: repeat(3,220px);
    }
}


@media screen and (max-width: 768px) {
    .services--container{
        grid-template-columns:repeat(2,1fr) ;
    }
    .services--content{
        padding: 3.5rem 0.5rem  1.25rem 1,5rem;
    }
    .services--icon{
        font-size: 1.5rem;
    }
}

/* For small devices */
@media screen and (max-width: 576px) {
    .services--container{
         grid-template-columns: repeat(1,1fr);

    }
    .services--content{
        padding: 3rem 0 2rem 5rem;
    }
    .service--box--content{     
        align-items: center;
        justify-content: center;
        padding: 2rem 2rem 1.5rem 2.5rem;
    }

    .services--box--description{
        padding: 0;
    }
}
@media screen and (max-width: 380px) {
    .services--container{
        grid-template-columns: max-content;
    }
    .services--content{
        padding: 5rem;
    }

}