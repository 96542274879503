.footer{
    background-color: var(--container-color);
    border-top: 1px solid rgba(0,0,0,0.1);   
}

.footer--container{
    padding: 2rem 0 6rem;
}
.footer--title,.footer--link{
    color: var(--title-color-dark);
}
.footer--title{
    text-align: center;
    margin-bottom: var(--mb-2);
}
.footer--link:hover{
    color: var(--title-color-dark);
}
.footer--list{
    display: flex;
    justify-content: center;
    column-gap: 1.5rem;
    color: var(--title-color-dark);
    margin-bottom: var(--mb-2);
}
.footer--social{
    display: flex;
    justify-content: center;
    column-gap: 1.25rem;
}
.footer--social--icon{
    background-color: var(--title-color);
    color: var(--container-color);
    color: var(--container-color);
    font-size: 1.3rem;
    padding: .5rem;
    border-radius:0.5rem ;
    display: inline-flex;
}
.footer--copyright{
    display: block;
    margin-top: 3rem;
    color: var(--title-color);
    text-align: center;
    font-size: var(--smaller-font-size);
}