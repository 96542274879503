.about--container{
    grid-template-columns: repeat(2,1fr);
    align-items: center;
    column-gap: 4rem;
}

.about--img{
    width: 400px;
    border-radius: 1.5rem;
    justify-self: center;
}
.about--info{
    grid-template-columns: repeat(2,220px);
    gap: .5rem;
    margin-bottom: var(--mb-2);
}

.about--box{
    background-color: var(--container-color);
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: .75rem;
    text-align: center;
    padding: 1rem 1.25rem;
}

.about--icon{
    font-size: 1.5rem;
    color: var(--title-color);
    margin-bottom: var(--mb-0-5);
}
.about--title{
    font-size: 1.2rem;
    font-weight: var(--tiny-font-size);
}
.about--sub{
    font-size: .8rem;
}

.about--description{
 padding: 0 4rem 0 0;
 margin-bottom: var(--mb-2-5);    
}


/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .about--container{
        grid-template-columns: 1fr;
        align-items: center;
        row-gap: 2.5rem;
    }

    .about--img{
        width: 300px;
    }

    .about--box{
        padding: .75rem .5rem;
    }

    .about{
        text-align: center;
    }
    .about--info{
        justify-content: center;
    }
    .about--description{
        padding: 0 5rem;
        margin-bottom:2rem ;
    }
}


@media screen and (max-width: 576px) {

    .about--info{
        grid-template-columns: repeat(2,1fr);
    }
    .about--description{
        padding: 0;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {

}