.home--content{
    grid-template-columns: 116px repeat(2,1fr);
    padding-top: 5.5rem;
    column-gap: 2rem;
    align-items: center;
}

.home--social{
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;
}

.social--icon{
    font-size: 1.5rem;
    color: var(--title-color);
}
.social--icon:hover{
    color: var(--title-color-dark);   
}

.home--img{
    background: url(../../assets/photo-removebg-preview.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow:inset 0 0 0 9px  rgb(255 255 255 / 30%) ;
    order: 2;
    justify-self: center;
    width: 320px;
    height: 320px;
    animation: p--animation 7s ease-in-out infinite 1s;
}
.home--title{
    font-size: var(--big-font-size);
    margin-bottom: var(--mb-0-25);
}
.home--hand{
    width: 38px;
    width: 38px;
    margin-left: 0.4rem;
}
.home--sub{
    position: relative;
    font-size: var(--h3-font-size);
    padding-left: 5.4rem;
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1);
}
.home--sub:hover{
    content: '';
    position: absolute;
    width: 70px;
    height: 1px;
    background-color: var(--text-color);
    left: 0;
    top: 1rem;
}
.home--description{
    min-width: 400px;
    margin-bottom: var(--mb-2);
}

.scroll--button{
    margin-left: 9.25rem;
}
.wheel{
    animation: scroll 2s ease infinite;
}
.scroll-name{
    color: var(--title-color);
    font-weight: var(--font-medium);
    margin-right: var(--mb-0-25);
    margin-left: 2px;
}
.scroll-arrow{
    font-size: 1.25rem;
    color: var(--title-color);
}


@keyframes scroll{
    0%{
        transform: translateY(0);
    }
    30%{
        transform: translateY(3.75rem);
    }
}
@keyframes p--animation{
    0%{
        border-radius:60% 40% 30% 70%/60% 30% 70% 40%  ;
    }
    50%{
        border-radius:30% 60% 70% 40%/50% 60% 30% 60%  ;
    }
    100%{
        border-radius:60% 40% 30% 70%/60% 30% 70% 40%  ;
    }

}


@media screen and (max-width: 992px) {
.home--content{
    column-gap: 1.25rem;
}
.home--hand{
    width: 26px;
    height: 26px;
}
.home--sub{
    padding-left: 3.75rem;
    margin-bottom: var(--mb-1);
}
.home--sub::before{
    width: -42px;
    top: 0.8rem;
}
.home--description{
    min-width: initial;
    margin-bottom: var(--mb-2-5);
}
.home--img{
    width: 270px;
    height: 270px;
    box-shadow:inset 0 0 0 7px  rgb(255,255,255 / 30%) ;
}
.home--scroll{
    margin-left: 7.5rem;
}
.home--title{
    font-size: 2.7rem;
}

}
/* For medium devices */
@media screen and (max-width: 768px) {
    .home--content{
        display: grid;
        grid-template-columns:0.5fr 3fr ;
        padding-top: 3.5rem;
    }
    .home--img{
        order: initial;
        
        box-shadow:inset 0 0 0 6px  rgb(255 255 255 / 30%) ;
        width: 220px;
        height: 220px;

    }
    .home--data{
        grid-column: 1/3;
    }
    .home--scroll{
        display: none;
    }

}



/* For small devices */
@media screen and (max-width: 350px) {
.home--img{
    width: 180px;
    height: 180px;
}
.home--hand{
    width: 22px;
    height: 22px;
}
}