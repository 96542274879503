.header{
    width: 100%;
    position: fixed;
    top: 0;
    left:0;
    z-index: var(--z-fixed);
    background-color: var(--container-color);
}
.nav{
    height: calc(var(--header-height) + 1.5rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
}
.nav--logo,.nav--toggle{

    color: var(--title-color);
    font-weight: var(--font-medium);
}
.nav--list{
    display: flex;
    column-gap: 2rem;
}
.nav--link{
    display: flex;
    flex-direction: column;
    align-items: column;
    color: var(--title-color);
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
    transition: .3s;
}


.nav__icon,.nav--close,.nav--toggle{
    display: none;
}

/*active links*/
.active--link,.nav--link:hover{
    color: var(--title-color-dark);
}



@media screen and (max-width: 768px) {
    .header{
        top: initial;
        bottom: 0;
    }
    .nav{
        height: var(--he);
    }
    .nav--menu{
        position: fixed;
        bottom: -100%;
        left: 0;
        width: 100%;
        background-color: var(--body-color);
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0,0,0,0.15);
        border-radius: 1.5rem 1.5rem  0 0;
        transition: .3s;
    }

    /* show Menu items */
    .show--menu{
        bottom: 0;
    }

    .nav--list{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        text-align: center;
    }

    .nav--close{
        position: absolute;
        right:1.3rem;
        bottom: .5rem;
        font-size: 1.5rem;
        cursor: pointer;
        color: var(--title-color);
    }

    .nav--toggle{
        font-size: 1.1rem;
        cursor: pointer;
    }

    .nav--close:hover{
        color: var(--title-color-dark);
    }

    .nav__icon{
        font-size: 1.3rem;
    }
    .nav--close,.nav--toggle,.nav__icon{
        display: block;
    }
}

@media screen and (max-width: 350px) {
    .nav--menu{
        padding: 2rem 0.25rem 4rem ;
    }
    .nav--list{
        column-gap: 0;
    }
}