.contact--container{
    grid-template-columns: repeat(2,max-content);
    justify-content: center;
    column-gap: 5rem;
    padding-bottom: 3rem;
}
.contact--title{
    text-align: center;
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1);
}
.contact--info{
    display: grid;
    row-gap: 2rem;
    grid-template-columns: repeat(300px);
}
.contact--box{
    background-color: var(--container-color);
    border: 1px solid rgba(0,0,0,0.1);
    padding: 1rem;
    border-radius: 0.75rem;
    text-align: center;
}
.contact--box--icon{
    font-size: 2rem;
    color: var(--title-color);
    margin-bottom: var(--mb-0-25);
}
.contact--box--title,.contact--box--data{
    font-size: var(--small-font-size);
}
.contact--box--title{
    font-weight: var(--font-medium);   
}
.contact--box--data{
    display: block;
    margin-bottom: var(--mb-0-75);
}

.contact--button{
    color: var(--text-color);
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.25rem;
}

.contact--form{
    width: 360px;

}
.contact--button--icon{
 font-size: 1rem;
 transition: 0.3s;   
}
.contact--button:hover .contact--box--icon{
    transform: translate(0.25rem);
}
.contact--form--items{
    position: relative;
    margin-bottom: var(--mb-2);
    height: 4rem;
}
.contact--form--input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(0, 0, 0, 0.1);
    background: none;
    color: var(--text-color);
    outline: none;
    border-radius: 0.75rem;
    padding: 1.5rem;
    z-index: 1;
}
.contact--form--tag{
    position: absolute;
    top: -.75rem;
    left: 1.25rem;
    font-size: var(--small-font-size);
    padding: 0.25rem;
    background-color: var(--body-color);
    z-index: 100;
}
.contact--form--area{
    height: 11rem;
}
.contact--form--area textarea{
    resize: none;
}




@media screen and (max-width: 992px) {
.contact--content{
    column-gap: 3rem;
}

}
/* For medium devices */
@media screen and (max-width: 768px) {
    .contact--container{
        grid-template-columns:1fr;
        row-gap: 3rem;
    }
    .contact--info{
        justify-content: center;
    }
    .contact--form{
        margin: 0 auto;
    }

}



/* For small devices */
@media screen and (max-width: 576px) {
    .contact--info{
        grid-template-columns: 1fr;
    }
    .contact--form{
        width: 100%;
    }
}

